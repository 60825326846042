import React, { useState } from "react";
import "./style.scss";
import { BlockPOC } from "@components/underwriting";
import Card from "@components/card";

export default function QuotePOCBlock({
  data,
  onChange,
  franchiseeData,
  setFranchiseeData,
  customToken
}) {
  return (
    <Card className="qu_poc" title="Point of Contact">
      <BlockPOC
        data={data}
        onChange={onChange}
        disableAdd={!franchiseeData}
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
        customToken={customToken}
      />
    </Card>
  );
}
