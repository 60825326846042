import { Select } from "antd";
import React, { useMemo } from "react";
import "./style.scss";

export default function CodeInput({ options, value, onChange }) {
  const codeOptions = useMemo(() => {
    return options.map((item) => ({
      value: item.code,
      label: `${item.code} - ${item.description}`
    }));
  }, [options]);

  return (
    <div>
      <p className="input-title">Class Code</p>
      <Select
        style={{ width: "100%" }}
        options={codeOptions}
        size="large"
        value={value}
        onChange={onChange}
      ></Select>
    </div>
  );
}
