import React, { useState, useMemo, useEffect } from "react";
import { format } from "date-fns";
import { Input, Card } from "@components";
import _ from "lodash";
import "./style.scss";
import { Button, notification } from "antd";
import { Select } from "antd";
import ReactJson from "react-json-view";
import { FORM_EDIT, FORM_VIEW } from "@assets/const/ui";
import Loading from "@components/loading";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import ScreenMask from "@components/screen-mask";
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined
} from "@ant-design/icons";
import { BlockContractor } from "@components/underwriting";

const { Option } = Select;

export default function PanelSubContractor({
  franchiseeData,
  franchisorData,
  setFranchiseeData,
  onNext
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [formVal, setFormVal] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [franchiseeData]);

  const codeList = useMemo(() => {
    if (franchisorData?.naics_classes?.length > 0) {
      return franchisorData?.naics_classes
        .filter((item) => item.eleType === "WC")
        .map((item) => ({
          code: item.code,
          description: item.description
        }));
    }

    return null;
  }, [franchisorData]);

  function handleGetFormVal() {
    const subcontractorList = franchiseeData?.subcontractorList || [];

    return [...subcontractorList];
  }

  async function handleSave() {
    setLoading(true);
    try {
      const logSubcontractorList = franchiseeData?.logSubcontractorList || [];

      logSubcontractorList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          subcontractorList: formVal,
          logSubcontractorList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "Subcontractor Expenses are successfully updated!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Subcontractor Expenses failed! Please contact admin directly.",
        duration: 5
      });
    }
    setLoading(false);
  }

  const locationList = useMemo(() => {
    return (franchiseeData?.commonLocList || []).map((item, index) => ({
      value: index,
      label: `${item.address}, ${item.city}, ${item.state}, ${item.zip}`
    }));
  }, [franchiseeData]);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [franchiseeData]);

  const isFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetFormVal());
    const changedStr = JSON.stringify(formVal);

    return originalStr != changedStr;
  }, [formVal, franchiseeData]);

  return (
    <div className="pan_contract">
      <div className="pan_contract-info">
        <BlockContractor
          data={formVal}
          codeList={codeList}
          locationList={locationList}
          onChange={setFormVal}
        />
        <div className="pan_contract-info-footer">
          <Button
            onClick={() => {
              if (isFormValDiff) {
                handleSave();
              } else {
                onNext();
              }
            }}
            size="large"
            type="primary"
          >
            {isFormValDiff ? "Save & Next" : "Everything Looks Good. Next"}
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
